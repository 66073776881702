<template>
  <!-- Print Card -->
  <b-card class="bus-roster-card mb-2" body-class="pt-0" :img-src="image" img-alt="Registration Sign In" img-top tag="article">
    <b-card-title class="mb-0 d-flex align-items-center justify-content-between">
      Registration Sign-In
    </b-card-title>
    <b-card-text class="mt-50">
      <p>
        <span>To print all hotel sign-in sheets, click the button below. </span>
        <span class="font-small-3 d-block">To configure custom groupings, click <u><b-link class="font-weight-bold" @click="showConfig = !showConfig">here.</b-link></u></span>
      </p>

      <b-row>
        <b-col v-if="showConfig" align-self="center">
          <b-form-group label="From" label-for="group-from">
            <b-input id="group-from" v-model="from" placeholder="A" ></b-input>
          </b-form-group>
        </b-col>
        <b-col v-if="showConfig" align-self="center">
          <b-form-group label="To" label-for="group-to">
            <b-input id="group-to" v-model="to" placeholder="L" ></b-input>
          </b-form-group>
        </b-col>
        <b-col v-if="showConfig" align-self="center" cols="auto">
          <b-button variant="primary" class="btn-icon mt-75" @click="addGroup">
            <b-icon-plus />
          </b-button>
        </b-col>
        <b-col v-if="showConfig" cols="12">
          <v-select
              v-model="selected.preset"
              :options="presets"
              :reduce="(option) => ({ to: option.to, from: option.from })"
              :selectable="(option) => !groups.some(group => group.from === option.from && group.to === option.to)"
              label="name" placeholder="Select a preset"
              class="mb-1"
              @input="(reducedValue) => addPreset(reducedValue)">
          </v-select>
        </b-col>
        <b-col cols="12">
          <b-list-group class="mb-1">
            <b-list-group-item v-for="(group) in groups" :key="group.id" class="d-flex justify-content-between align-items-center">
              <div>{{ group.from }} - {{ group.to }}</div>

              <b-button v-if="showConfig" variant="link" size="sm" class="btn-icon pr-0" @click="removeGroup(group)">
                <b-icon-trash-fill />
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>

      <span class="font-small-3">For individual sign-in sheets, please select a hotel from the list and click the printer icon to the right of the 'Registration Sign-In' tab.</span>
    </b-card-text>
    <b-button block variant="primary" @click="$emit('ready', groups)">Print Sign-In Sheets</b-button>
  </b-card>
</template>


<script>
import {uuid} from 'vue-uuid';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import RegistrationModal from '@/views/events/hotels/RegistrationModal.vue';

export default {
  name: 'GroupRanges',
  components: {
    RegistrationModal,
    BCardActions,
    vSelect
  },
  props: {
    value: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      selected: {
        preset: null
      },
      from: '',
      to: '',
      presets: [
        { name: 'A-L', from: 'A', to: 'L' },
        { name: 'M-Z', from: 'M', to: 'Z' }
      ],
      // eslint-disable-next-line global-require
      image: require('@/assets/images/illustration/storyset-online-document-rafiki.svg'),
      showConfig: false
    }
  },
  computed: {
    groups: {
      get() {
        // Getter for the groups, returns the value passed down from the parent
        return this.value;
      },
      set(newValue) {
        // Setter for the groups, emits an update to the parent when groups change
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    addPreset(value) {
      this.groups.push({
        id: uuid.v4(),
        from: value.from,
        to: value.to
      });
      this.selected.preset = null;
    },
    addGroup() {
      this.groups = [...this.groups, {
        from: this.from.toUpperCase(),
        to: this.to.toUpperCase()
      }];
      this.from = '';
      this.to = '';
    },
    removeGroup(group) {
      this.groups = this.groups.filter(g => g.id !== group.id);
    },
    validateInput(from, to) {
      if (!from || !to || from >= to) {
        return false;
      }
      const newFrom = from.toUpperCase().charCodeAt(0);
      const newTo = to.toUpperCase().charCodeAt(0);
      return !this.groups.some(group => {
        const groupFrom = group.from.charCodeAt(0);
        const groupTo = group.to.charCodeAt(0);
        return newFrom <= groupTo && newTo >= groupFrom;
      });
    }
  }
}
</script>

