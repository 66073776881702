<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col-6 px-0">
            <v-select v-model="search"
                      :options="selectedHotelStudentEvents"
                      :filter-by="(option, label, search) =>
                                      option.student.name.first.toLowerCase().includes(search.toLowerCase())
                                      || option.student.name.last.toLowerCase().includes(search.toLowerCase())"
                      select-on-tab
                      label="id"
                      :reduce="option => option.id"
                      placeholder="Search..."
                      class="w-100">
              <template #option="{ student }">
                {{ getFullName(student) }}
              </template>
              <template #selected-option="{ student }">
                {{ getFullName(student) }}
              </template>
            </v-select>
          </div>
          <div>
            <b-dropdown v-if="groups.length" right :text="selectedGroup ? `${selectedGroup.from}-${selectedGroup.to}` : ''" variant="link" no-caret>
              <b-dropdown-item v-for="group in groups" :key="group.id"
                               :active="isSelectedGroup(group)"
                               @click="selectedGroup = group">
                {{ group.from }}-{{ group.to }}
              </b-dropdown-item>
            </b-dropdown>
            <b-button size="sm" variant="link" class="btn-icon" @click="ui.sorting.show = !ui.sorting.show">
              <b-icon-sort-down style="width: 14px; height: 14px"/>
            </b-button>
            <b-button size="sm" variant="link" class="btn-icon" @click="ui.filters.show = !ui.filters.show">
              <b-icon :icon="isFiltered ? 'funnel-fill' : 'funnel'" style="width: 14px; height: 14px"/>
            </b-button>
          </div>
        </div>
      </b-card-title>

      <b-row>
        <b-col v-if="ui.filters.show" cols="12">
          <b-card bg-variant="light-primary" body-class="px-1" class="mb-2">
            <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
              <span v-b-toggle="'roomAssignment-filters-collapse'">Filters</span>
              <div class="d-flex align-items-center">
                <b-badge v-if="isFiltered" title="Active Filters" variant="primary" pill class="mr-1">{{ activeFilters.length }}</b-badge>
                <b-icon-x v-else style="width: 15px; height: 15px;"
                          class="cursor-pointer mr-1"
                          @click="ui.filters.show = false"/>
                <b-icon-chevron-down style="width: 14px; height: 14px;"
                                     class="cursor-pointer"
                                     :class="ui.filters.expanded ? 'rotate-180' : 'collapsed'"
                                     aria-controls="roomAssignment-filters-collapse"
                                     :aria-expanded="ui.filters.expanded ? 'true' : 'false'"
                                     @click="ui.filters.expanded = !ui.filters.expanded "/>
              </div>
            </div>
            <b-collapse id="roomAssignment-filters-collapse" v-model="ui.filters.expanded">
              <b-row class="mt-1">
                <b-col>
                  <b-form-group label="Instrument" label-for="filter-instruments">
                    <v-select v-model="filters.instrument"
                              input-id="filter-instruments"
                              placeholder="Instrument"
                              :options="instruments" label="name"
                              :reduce="option => option.id"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100 font-small-3"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Ensemble" label-for="filter-instruments">
                    <v-select v-model="filters.ensemble"
                              input-id="filter-ensembles"
                              placeholder="Ensemble"
                              :options="ensembles" label="name"
                              :reduce="option => option.id"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100 font-small-3"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Chaperone" label-for="filter-chaperone">
                    <v-select v-model="filters.chaperone"
                              input-id="filter-chaperone"
                              placeholder="Chaperone"
                              :loading="selected.hotel.chaperones.loading"
                              :options="selected.hotel.chaperones.items" label="name"
                              :reduce="option => option.id"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100 font-small-3">
                      <template #option="{ name }">
                          <span :class="{'text-ui-danger font-small-3': !name.first || !name.last}">
                            {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                          </span>
                      </template>
                      <template #selected-option="{ name }">
                          <span :class="{'text-ui-danger': !name.first || !name.last}">
                            {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                          </span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </b-col>
        <b-col v-if="ui.sorting.show">
          <b-card bg-variant="light-primary" body-class="px-1" class="mb-2">
            <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
              <span v-b-toggle="'roomAssignment-sorting-collapse'">Sorting</span>
              <div class="d-flex align-items-center">
                <b-badge v-if="sorting.by.length" title="Active Sorting" variant="primary" pill class="mr-1">{{ sorting.by.length }}</b-badge>
                <b-icon-x v-else style="width: 15px; height: 15px;"
                          class="cursor-pointer mr-1"
                          @click="ui.sorting.show = false"/>
                <b-icon-chevron-down style="width: 14px; height: 14px;"
                                     class="cursor-pointer"
                                     :class="ui.sorting.expanded ? 'rotate-180' : 'collapsed'"
                                     aria-controls="roomAssignment-sorting-collapse"
                                     :aria-expanded="ui.sorting.expanded ? 'true' : 'false'"
                                     @click="ui.sorting.expanded = !ui.sorting.expanded "/>
              </div>
            </div>
            <b-collapse id="roomAssignment-sorting-collapse" v-model="ui.sorting.expanded">
              <b-form-group label="Sort By" label-for="sorting-by" class="mt-1">
                <sort-input v-model="sorting"/>
              </b-form-group>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>

      <template>
        <vue-perfect-scrollbar :settings="scrollbarSettings" class="ps-hotel-registration">
          <b-list-group class="rounded p-1 bg-light-primary">
            <b-list-group-item v-for="(studentEvent, index) in selectedHotelStudentEvents"
                               :id="studentEvent.id" :key="studentEvent.id"
                               tag="li"
                               :action="false"
                               :class="['rounded border-0 shadow p-2', index === 0 ? 'mt-0' : 'mt-1']">
              <b-row class="match-height">
                <b-col cols="auto">
                  <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(studentEvent.student)">
                    <font-awesome-icon :icon="getSexIcon(studentEvent.student)" style="width: 22px; height: 22px" />
                    <template #badge>
                      {{ getSexBadge(studentEvent.student) }}
                    </template>
                  </b-avatar>
                </b-col>
                <b-col cols="3">
                  <div class="truncate">
                    <h5 class="mb-25 font-weight-bolder">
                      {{ getFullName(studentEvent.student, { lnf: true }) }}
                    </h5>
                    <div class="font-small-3">
                      Room {{ studentEvent.room }}
                    </div>
                    <div :data="application = getApplicationWithSelection(studentEvent.student.applications)" class="font-small-3">
                      {{ application.selection.ensemble.name }} - {{ application.instrument.name }}
                    </div>
                  </div>
                </b-col>
                <b-col cols="auto">
                  <b-avatar :variant="`light-primary`" size="44px">
                    <font-awesome-icon icon="fa-solid fa-signature" style="width: 22px; height: 22px"/>
                  </b-avatar>
                </b-col>
                <b-col>
                  <vue-signature :ref="`signature-${studentEvent.id}`" :disabled="disabled" :default-url="studentEvent.signature ? studentEvent.signature : undefined"/>
                  <b-card-footer v-if="!disabled" class="px-0">
                    <div class="d-flex justify-content-end">

                      <b-button variant="outline-primary" size="sm" class="btn-icon d-flex align-self-center mr-50" @click="undo(studentEvent.id)">
                        <b-icon-arrow-clockwise class="mr-05"/>
                        <span class="my-auto">Undo</span>
                      </b-button>
                      <b-button variant="outline-danger" size="sm" class="btn-icon d-flex align-self-center mr-1" @click="clear(studentEvent.id)">
                        <b-icon-trash class="mr-05"/>
                        <span class="my-auto">Clear</span>
                      </b-button>
                      <b-button variant="primary" size="sm" class="btn-icon d-flex align-self-center mr-0" @click="updateStudentEventSignature(studentEvent)">
                        <b-icon-save class="mr-05"/>
                        <span class="my-auto">Save</span>
                      </b-button>
                    </div>
                  </b-card-footer>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import Fuse from 'fuse.js';
import events from '@/mixins/event.mixin';
import notify from '@/mixins/notify.mixin';
import vueSignature from 'vue-signature';
import {updateStudentEvent} from '@/views/events/service';
import HotelRoom from '@/views/events/hotels/HotelRoom.vue';
import SortInput from '@/components/SortInput.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'HotelRegistration',
  components: {
    BCardActions,
    SortInput,
    HotelRoom,
    vSelect,
    vueSignature,
    VuePerfectScrollbar
  },
  mixins: [ events, notify ],
  props: {
    selected: {
      type: Object,
      required: true
    },
    groups: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: '',
      filters: {
        instrument: null
      },
      sorting: {
        by: ['room', 'name.last', 'name.first', 'type'],
        desc: false,
        drag: false,
        options: [
          { label: 'First Name', value: 'name.first'},
          { label: 'Last Name', value: 'name.last'},
          { label: 'Instrument', value: 'raw.applications.items.instrument.name'},
          { label: 'Type', value: 'type'},
          { label: 'Room', value: 'room'},
        ]
      },
      selectedGroup: null,
      ui: {
        filters: {
          show: false,
          expanded: false
        },
        sorting: {
          show: false,
          expanded: false
        },
        grouped: {
          show: false,
          expanded: false
        }
      },
      disabled: true,
      icon: 'fa-solid fa-signature'
    }
  },
  computed: {
    selectedHotelStudentEvents() {
      if(this.selected.loading) return []
      const studentEvents = this.selectedGroup === null
        ? this.selected.hotel.studentEvents.items
        : this.selected.hotel.studentEvents.items
          .toSorted((a, b) => {
            const nameA = a.student ? this.getFullName(a.student, { lnf: true }) : '';
            const nameB = b.student ? this.getFullName(b.student, { lnf: true }) : '';
            return nameA.localeCompare(nameB);
          })
          .filter(studentEvent => {
            const lastName = studentEvent.student ? studentEvent.student.name.last.toUpperCase() : '';
            const from = this.selectedGroup.from.toUpperCase();
            const to = this.selectedGroup.to.toUpperCase();

            // Compare directly between the full "from" and "to" values
            return lastName.localeCompare(from) >= 0 && lastName.localeCompare(to) <= 0;
          });

      const { chaperone, instrument, ensemble } = this.filters
      const fuse = new Fuse(studentEvents, {
        useExtendedSearch: true,
        threshold: 0.3,
        keys: [
          'id',
          'student.id',
          'student.name.first',
          'student.name.last',
          'chaperoneId',
          'student.applications.items.instrument.id',
          'student.applications.items.selection.ensemble.id',
        ]
      })
      const query = { $and: [ ] }
      if(chaperone) { query.$and.push({ chaperoneId: chaperone }) }
      if(instrument) { query.$and.push({ 'student.applications.items.instrument.id': instrument }) }
      if(ensemble) { query.$and.push({ 'student.applications.items.selection.ensemble.id': ensemble }) }

      if(this.search) {
        query.$and.push({
          $or: [
            { id: `${this.search}` },
            { 'student.id': `${this.search}` },
            { 'student.name.first': `${this.search}` },
            { 'student.name.last': `${this.search}`}
          ]
        })
      }

      let items;
      if(query.$and.length) { items = fuse.search(query).map(({ item }) => item) }
      else { items = studentEvents }
      if (this.sorting?.by?.length) {
        return this.sortItems(this.sorting, items);
      }
      return items
    },
    instruments() {
      if(!this.selected.hotel.studentEvents.items.length) return []
      const instruments = this.selected.hotel.studentEvents.items.map(i => {
        const application = this.getApplicationWithSelection(i.student.applications);
        return application?.instrument;
      })
      return instruments
          .filter((instrument, index) => instrument && instruments.findIndex(i => i.id === instrument.id) === index)
          .sort((a, b) => a.name.localeCompare(b.name));
    },
    ensembles() {
      if(!this.selected.hotel.studentEvents.items.length) return []
      const ensembles = this.selected.hotel.studentEvents.items.map(i => {
        const application = this.getApplicationWithSelection(i.student.applications);
        return application?.selection?.ensemble;
      })
      return ensembles
          .filter((ensemble, index) => ensemble && ensembles.findIndex(i => i.id === ensemble.id) === index)
          .sort((a, b) => a.name.localeCompare(b.name));
    },
    isFiltered() {
      return this.search || (this.filters && Object.values(this.filters).some(f => f !== null));
    },
    activeFilters() {
      return Object.values(this.filters).filter(f => f !== null);
    },
  },
  watch: {
    selectedHotelStudentEvents: {
      handler(value) {
        this.disabled = value.length !== 1;
      }
    }
  },
  mounted() {
    if(this.groups.length === 1) {
      const group = this.groups[0]
      this.selectedGroup = group
    }
  },
  methods: {
    getApplicationWithSelection(applications) {
      return applications.items.find(application => application.selection?.accepted)
    },
    isSelectedGroup(group) {
      return this.selected.group && this.selected.group.id === group.id
    },

    async updateStudentEventSignature(studentEvent) {
      studentEvent.signature = this.getSmalledSaveType(studentEvent)

      const { id, signature } = studentEvent
      await updateStudentEvent({ id, signature }).then(event => {
        this.notify({ title: 'Success', text: 'Signature was successfully updated', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Signature failed to update', icon: this.icon, variant: 'danger'});
      })
    },


    getSmalledSaveType(studentEvent) {
      const ref = this.getSignatureRef(studentEvent.id)
      const png = ref.save();
      const jpeg = ref.save('image/jpeg');
      const svg = ref.save('image/svg+xml');

      const lengths = {
        png: png.length,
        jpeg: jpeg.length,
        svg: svg.length
      };

      // Determine which file has the smallest length
      const type = Object.keys(lengths).reduce((smallest, key) => (lengths[key] < lengths[smallest] ? key : smallest), 'png');

      if(type === 'jpeg') return jpeg
      if(type === 'svg') return svg
      return png
    },
    clear(id) {
      const ref = this.getSignatureRef(id)
      ref.clear();
    },
    undo(id) {
      const ref = this.getSignatureRef(id)
      ref.undo();
    },
    fromDataURL(id, url) {
      const ref = this.getSignatureRef(id)
      ref.fromDataURL(url);
    },
    getSignatureRef(id) {
      return this.$refs[`signature-${id}`][0]
    },
  }
}
</script>

<style scoped lang="scss">
.ps-hotel-registration {
  max-height: 60vh;

  .list-group-item:hover {
    background-color: var(--white);
    cursor: default;
  }

  .list-group:empty:before,
  .list-group > div:empty:before {
    content: 'No Students Associated With This Hotel...';
  }
}

.ps--active-y:hover {
  padding-right: 1rem;
  transition: 0.25s;
}

.ps__rail-y:hover>.ps__thumb-y {
  width: 6px;
}
</style>
