<template>
  <b-overlay :show="isLoading" opacity="1" bg-color="white" style="min-height: 100px">
    <b-card no-body>
      <b-card-body>
        <b-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-6 px-0">
              <v-select v-model="search"
                        :options="occupants"
                        :filter-by="(option, label, search) =>
                          option.name.first.toLowerCase().includes(search.toLowerCase())
                          || option.name.last.toLowerCase().includes(search.toLowerCase())"
                        select-on-tab
                        label="id"
                        :reduce="option => option.id"
                        placeholder="Search..."
                        class="w-100">
                <template #option="{ raw }">
                  {{ getFullName(raw) }}
                </template>
                <template #selected-option="{ raw }">
                  {{ getFullName(raw) }}
                </template>
              </v-select>
            </div>
            <div>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.grouped = !ui.grouped">
                <b-icon :icon="ui.grouped ? 'diagram3-fill' : 'diagram3'" style="width: 15px; height: 15px"/>
              </b-button>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.sorting.show = !ui.sorting.show">
                <b-icon-sort-down style="width: 14px; height: 14px"/>
              </b-button>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.filters.show = !ui.filters.show">
                <b-icon :icon="isFiltered ? 'funnel-fill' : 'funnel'" style="width: 14px; height: 14px"/>
              </b-button>
            </div>
          </div>
        </b-card-title>

        <b-row>
          <b-col v-if="ui.filters.show" cols="12">
            <b-card bg-variant="light-primary" body-class="px-1" class="mb-2">
              <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
                <span v-b-toggle="'roomAssignment-filters-collapse'">Filters</span>
                <div class="d-flex align-items-center">
                  <b-badge v-if="isFiltered" title="Active Filters" variant="primary" pill class="mr-1">{{ activeFilters.length }}</b-badge>
                  <b-icon-x v-else style="width: 15px; height: 15px;"
                            class="cursor-pointer mr-1"
                            @click="ui.filters.show = false"/>
                  <b-icon-chevron-down style="width: 14px; height: 14px;"
                                       class="cursor-pointer"
                                       :class="ui.filters.expanded ? 'rotate-180' : 'collapsed'"
                                       aria-controls="roomAssignment-filters-collapse"
                                       :aria-expanded="ui.filters.expanded ? 'true' : 'false'"
                                       @click="ui.filters.expanded = !ui.filters.expanded "/>
                </div>
              </div>
              <b-collapse id="roomAssignment-filters-collapse" v-model="ui.filters.expanded">
                <b-row class="mt-1">
                  <b-col>
                    <b-form-group label="Instrument" label-for="filter-instruments">
                      <v-select v-model="filters.instrument"
                                input-id="filter-instruments"
                                placeholder="Instrument"
                                :options="instruments" label="name"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3"/>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Ensemble" label-for="filter-instruments">
                      <v-select v-model="filters.ensemble"
                                input-id="filter-ensembles"
                                placeholder="Ensemble"
                                :options="ensembles" label="name"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3"/>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Chaperone" label-for="filter-chaperone">
                      <v-select v-model="filters.chaperone"
                                input-id="filter-chaperone"
                                placeholder="Chaperone"
                                :options="chaperones.items" label="name"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3">
                        <template #option="{ name }">
                          <span :class="{'text-ui-danger font-small-3': !name.first || !name.last}">
                            {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                          </span>
                        </template>
                        <template #selected-option="{ name }">
                          <span :class="{'text-ui-danger': !name.first || !name.last}">
                            {{ name.first || name.last ? getFullName({ name: name }) : 'Unnamed Chaperone' }}
                          </span>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
          </b-col>
          <b-col v-if="ui.sorting.show">
            <b-card bg-variant="light-primary" body-class="px-1" class="mb-2">
              <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
                <span v-b-toggle="'roomAssignment-sorting-collapse'">Sorting</span>
                <div class="d-flex align-items-center">
                  <b-badge v-if="sorting.by.length" title="Active Sorting" variant="primary" pill class="mr-1">{{ sorting.by.length }}</b-badge>
                  <b-icon-x v-else style="width: 15px; height: 15px;"
                            class="cursor-pointer mr-1"
                            @click="ui.sorting.show = false"/>
                  <b-icon-chevron-down style="width: 14px; height: 14px;"
                                       class="cursor-pointer"
                                       :class="ui.sorting.expanded ? 'rotate-180' : 'collapsed'"
                                       aria-controls="roomAssignment-sorting-collapse"
                                       :aria-expanded="ui.sorting.expanded ? 'true' : 'false'"
                                       @click="ui.sorting.expanded = !ui.sorting.expanded "/>
                </div>
              </div>
              <b-collapse id="roomAssignment-sorting-collapse" v-model="ui.sorting.expanded">
                <b-form-group label="Sort By" label-for="sorting-by" class="mt-1">
                  <sort-input v-model="sorting"/>
                </b-form-group>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>

        <template v-if="ui.grouped">
          <template v-if="selectedHotelFloors.size">
            <b-card-actions v-for="floor in selectedHotelFloors" :key="floor"
                            action-collapse
                            :action-container-classes="{ collapse: 'pr-1'}"
                            header-class="pt-1 bg-light-primary"
                            body-class="pb-0 bg-light-primary">
              <template #title>
                Floor {{ floor }}
              </template>
              <template #default>
                <b-row class="match-height">
                  <b-col v-for="room in getFloorRooms(floor)" :key="room.id" class="col-12 col-xxl-6 col-xxxl-4 col-xxxxl-3">
                    <hotel-room :room="room">
                      <template #text="{ type, occupant }">
                        <div v-if="type === 'chaperone'">
                          {{ occupant.role }}
                        </div>
                        <div v-else>
                          <div :data="application = getApplicationWithSelection(occupant.applications)" class="font-small-3">
                            {{ application.selection.ensemble.name }} - {{ application.instrument.name }}
                          </div>
                          <div class="font-small-3">{{ occupant.school.name.legal }}</div>
                        </div>
                      </template>
                    </hotel-room>
                  </b-col>
                </b-row>
              </template>
            </b-card-actions>

            <b-card-actions v-if="unassignedOccupants.length" action-collapse :action-container-classes="{ collapse: 'pr-1'}"
                            header-class="pt-1 bg-light-primary"
                            body-class="pb-1 bg-light-primary">
              <template #title>
                Unassigned
              </template>
              <template #default>
                <b-list-group class="rounded p-1 bg-light-primary">
                  <b-list-group-item v-for="(occupant, index) in unassignedOccupants"
                                     :id="occupant.id" :key="occupant.id"
                                     tag="li"
                                     :action="false"
                                     :to="{ name: (isStudent(occupant) ? 'events-student' : 'events-chaperone'), params: { id: occupant.id }}"
                                     :class="['rounded border-0 shadow p-2', index === 0 ? 'mt-0' : 'mt-1']">
                    <b-row>
                      <b-col cols="auto" align-self="start">
                        <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(occupant.raw)">
                          <font-awesome-icon :icon="isStudent(occupant) ? getSexIcon(occupant.raw) : 'fas fa-people-roof'" style="width: 22px; height: 22px" />
                          <template v-if="isStudent(occupant)" #badge>
                            {{ getSexBadge(occupant.raw) }}
                          </template>
                        </b-avatar>
                      </b-col>
                      <b-col align-self="center">
                        <!--                    {{ occupant }}-->
                        <div class="truncate">
                          <h5 class="mb-25 font-weight-bolder">
                            {{ occupant.name.first }} {{ occupant.name.last }}
                          </h5>
                          <div v-if="isStudent(occupant)" :data="application = getApplicationWithSelection(occupant.raw.applications)" class="font-small-3">
                            {{ application.selection.ensemble.name }} - {{ application.instrument.name }}
                          </div>
                          <div class="font-small-3">
                            {{ selected.hotel.name }} - Room {{ isStudent(occupant) ? occupant.raw.event.room : occupant.raw.room }}
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </template>
            </b-card-actions>
          </template>
          <template v-else>
            <div class="ps-room-assignments">
              <b-list-group class="rounded p-1 bg-light-primary"/>
            </div>
          </template>
        </template>
        <template v-else>
          <vue-perfect-scrollbar :settings="scrollbarSettings" class="ps-room-assignments">
            <b-list-group class="rounded p-1 bg-light-primary">
              <b-list-group-item v-for="(occupant, index) in selectedHotelOccupants"
                                 :id="occupant.id" :key="occupant.id"
                                 tag="li"
                                 :to="{ name: (isStudent(occupant) ? 'events-student' : 'events-chaperone'), params: { id: occupant.id }}"
                                 :action="false"
                                 :class="['cursor-pointer rounded border-0 shadow p-2', index === 0 ? 'mt-0' : 'mt-1']">
                <b-row>
                  <b-col cols="auto" align-self="start">
                    <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(occupant.raw)">
                      <font-awesome-icon :icon="isStudent(occupant) ? getSexIcon(occupant.raw) : 'fas fa-people-roof'" style="width: 22px; height: 22px" />
                      <template v-if="isStudent(occupant)" #badge>
                        {{ getSexBadge(occupant.raw) }}
                      </template>
                    </b-avatar>
                  </b-col>
                  <b-col align-self="center">
                    <!--                    {{ occupant }}-->
                    <div class="truncate">
                      <h5 class="mb-25 font-weight-bolder">
                        {{ occupant.name.first }} {{ occupant.name.last }}
                      </h5>
                      <div v-if="isStudent(occupant)" :data="application = getApplicationWithSelection(occupant.raw.applications)" class="font-small-3">
                        {{ application.selection.ensemble.name }} - {{ application.instrument.name }}
                      </div>
                      <div class="font-small-3">
                        {{ selected.hotel.name }} - Room {{ isStudent(occupant) ? occupant.raw.event.room : occupant.raw.room }}
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </template>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import HotelRoom from '@/views/events/hotels/HotelRoom.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import {Occupant, Room} from '@/views/events/service';
import vSelect from 'vue-select';
import events from '@/mixins/event.mixin';
import Fuse from 'fuse.js';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import SortInput from '@/components/SortInput.vue';

export default {
  name: 'RoomAssignments',
  components: {
    SortInput,
    vSelect,
    BCardActions,
    HotelRoom,
    VuePerfectScrollbar
  },
  mixins: [ events ],
  props: {
    selected: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: '',
      filters: {
        instrument: null,
        ensemble: null,
        bus: null,
        chaperone: null,
      },
      sorting: {
        by: ['room', 'name.last', 'name.first', 'type'],
        desc: false,
        drag: false,
        options: [
          { label: 'First Name', value: 'name.first'},
          { label: 'Last Name', value: 'name.last'},
          { label: 'Instrument', value: 'raw.applications.items.instrument.name'},
          { label: 'Type', value: 'type'},
          { label: 'Room', value: 'room'},
        ]
      },
      chaperones: {
        loading: false,
        items: []
      },
      studentEvents: {
        loading: false,
        items: []
      },
      ui: {
        filters: {
          show: false,
          expanded: false
        },
        sorting: {
          show: false,
          expanded: false
        },
        grouped: true
      },
    }
  },
  computed: {
    selectedHotelRoomNumbers() {
      if(this.isLoading) return []
      const roomNumbers = this.studentEvents.items.map(e => e.room)
          .concat(this.chaperones.items.map(c => c.room))
      return Array.from(new Set(roomNumbers)).filter(r => r).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
    },
    selectedHotelFloors() {
      return new Set(this.selectedHotelRoomNumbers.map(roomNumber => this.getFloorNumber(roomNumber)))
    },
    occupants() {
      const occupants = []
      occupants.push(...this.chaperones.items.map(chaperone => new Occupant('chaperone', chaperone)))
      occupants.push(...this.studentEvents.items.map(event => new Occupant('student', {
        ...event.student,
        event: {
          id: event.id,
          chaperoneId: event.chaperoneId,
          chaperone: event.chaperone,
          hotelId: event.hotelId,
          hotel: event.hotel,
          room: event.room,
        }
      })))
      return occupants
    },
    unassignedOccupants() {
      const { chaperone, instrument, ensemble } = this.filters
      const occupants = this.occupants.filter(occupant => (this.isStudent(occupant) ? !occupant.raw.event.room : !occupant.raw.room))
      const fuse = new Fuse(occupants, {
        useExtendedSearch: true,
        keys: [
          'id',
          'name.first',
          'name.last',
          'raw.event.chaperoneId',
          'raw.applications.items.instrument.id',
          'raw.applications.items.selection.ensemble.id',
        ]
      })

      const query = { $and: [ ] }
      if(chaperone) { query.$and.push({ 'raw.event.chaperoneId': chaperone }) }
      if(instrument) { query.$and.push({ 'raw.applications.items.instrument.id': instrument }) }
      if(ensemble) { query.$and.push({ 'raw.applications.items.selection.ensemble.id': ensemble }) }
      if(this.search) {
        query.$and.push({
          $or: [
            { id: `'${this.search}` },
            { 'name.first': `'${this.search}` },
            { 'name.last': `'${this.search}` },
          ]
        })
      }

      let searchedItems;
      if(query.$and.length) { searchedItems = fuse.search(query).map(({ item }) => item) }
      else { searchedItems = occupants }

      if (this.sorting?.by?.length) {
        return this.sortItems(this.sorting, searchedItems);
      }
      return searchedItems;
    },
    selectedHotelOccupants() {
      const { chaperone, instrument, ensemble } = this.filters
      const fuse = new Fuse(this.occupants, {
        useExtendedSearch: true,
        keys: [
          'id',
          'name.first',
          'name.last',
          'raw.event.chaperoneId',
          'raw.applications.items.instrument.id',
          'raw.applications.items.selection.ensemble.id',
        ]
      })

      const query = { $and: [ ] }
      if(chaperone) { query.$and.push({ 'raw.event.chaperoneId': chaperone }) }
      if(instrument) { query.$and.push({ 'raw.applications.items.instrument.id': instrument }) }
      if(ensemble) { query.$and.push({ 'raw.applications.items.selection.ensemble.id': ensemble }) }

      if(this.search) {
        query.$and.push({
          $or: [
            { id: `'${this.search}` },
            { 'name.first': `'${this.search}` },
            { 'name.last': `'${this.search}` },
          ]
        })
      }

      let searchedItems;
      if(query.$and.length) { searchedItems = fuse.search(query).map(({ item }) => item) }
      else { searchedItems = this.occupants }

      if (this.sorting?.by?.length) {
        return this.sortItems(this.sorting, searchedItems);
      }
      return searchedItems;
    },
    instruments() {
      if(!this.selected.hotel.studentEvents.items.length) return []
      const instruments = this.selected.hotel.studentEvents.items.map(i => {
        const application = this.getApplicationWithSelection(i.student.applications);
        return application?.instrument;
      })
      return instruments
          .filter((instrument, index) => instrument && instruments.findIndex(i => i.id === instrument.id) === index)
          .sort((a, b) => a.name.localeCompare(b.name));
    },
    ensembles() {
      if(!this.selected.hotel.studentEvents.items.length) return []
      const ensembles = this.selected.hotel.studentEvents.items.map(i => {
        const application = this.getApplicationWithSelection(i.student.applications);
        return application?.selection?.ensemble;
      })
      return ensembles
          .filter((ensemble, index) => ensemble && ensembles.findIndex(i => i.id === ensemble.id) === index)
          .sort((a, b) => a.name.localeCompare(b.name));
    },
    isLoading() {
      return this.studentEvents?.loading || this.chaperones?.loading
    },
    isFiltered() {
      return this.search || (this.filters && Object.values(this.filters).some(f => f !== null));
    },
    activeFilters() {
      return Object.values(this.filters).filter(f => f !== null);
    }
  },
  watch: {
    selected: {
      deep: true,
      handler(value) {
        this.studentEvents = value.hotel.studentEvents
        this.chaperones = value.hotel.chaperones
      }
    }
  },
  mounted() {
    this.studentEvents = this.selected?.hotel?.studentEvents ?? { loading: true, items: [] }
    this.chaperones = this.selected?.hotel?.chaperones ?? { loading: true, items: [] }
  },
  methods: {
    getFloorNumber(number) {
      if(!number) return null
      return Number(String(number)[0])
    },
    getFloorRooms(floor) {
      const rooms = []
      const { chaperone, instrument, ensemble } = this.filters
      this.selectedHotelRoomNumbers.filter(roomNumber => this.getFloorNumber(roomNumber) === floor).forEach(roomNumber => {
        let occupants = []
        occupants.push(...this.selected.hotel.chaperones.items.filter(item => item.room === roomNumber).map(item => new Occupant('chaperone', item)))
        occupants.push(...this.selected.hotel.studentEvents.items.filter(item => item.room === roomNumber).map(item => new Occupant('student', {
          ...item.student,
          event: {
            id: item.id,
            chaperoneId: item.chaperoneId,
            chaperone: item.chaperone,
            hotelId: item.hotelId,
            hotel: item.hotel,
            room: item.room,
          }
        })))

        const fuse = new Fuse(occupants, {
          useExtendedSearch: true,
          keys: [
            'id',
            'name.first',
            'name.last',
            'raw.event.chaperoneId',
            'raw.applications.items.instrument.id',
            'raw.applications.items.selection.ensemble.id',
          ]
        })

        const query = { $and: [ ] }
        if(chaperone) { query.$and.push({ 'raw.event.chaperoneId': chaperone }) }
        if(instrument) { query.$and.push({ 'raw.applications.items.instrument.id': instrument }) }
        if(ensemble) { query.$and.push({ 'raw.applications.items.selection.ensemble.id': ensemble }) }

        if(this.search) {
          query.$and.push({
            $or: [
              { id: `'${this.search}` },
              { 'name.first': `'${this.search}` },
              { 'name.last': `'${this.search}`}
            ]
          })
        }

        if(query.$and.length) { occupants = fuse.search(query).map(({item}) => item) }
        if (this.sorting?.by?.length) {
          occupants = this.sortItems(this.sorting, occupants);
        }
        rooms.push(new Room(roomNumber, occupants))
      })
      rooms.sort((a, b) => a.number - b.number)
      return rooms.filter(room => room.occupants.length)
    },
    getApplicationWithSelection(applications) {
      return applications.items.find(application => application.selection?.accepted)
    },
    isStudent(occupant) {
      return occupant.type === 'student'
    },
  }
}
</script>

<style scoped lang="scss">
.ps-room-assignments {
  max-height: 60vh;

  .list-group-item:hover {
    background-color: var(--white);
    cursor: default;
  }

  .list-group:empty:before,
  .list-group > div:empty:before {
    content: 'No Students Associated With This Hotel...';
  }
}

.ps--active-y:hover {
  padding-right: 1rem;
  transition: 0.25s;
}

.ps__rail-y:hover>.ps__thumb-y {
  width: 6px;
}
</style>
