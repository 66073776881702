import { render, staticRenderFns } from "./HotelRegistration.vue?vue&type=template&id=458017a3&scoped=true&"
import script from "./HotelRegistration.vue?vue&type=script&lang=js&"
export * from "./HotelRegistration.vue?vue&type=script&lang=js&"
import style0 from "./HotelRegistration.vue?vue&type=style&index=0&id=458017a3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "458017a3",
  null
  
)

export default component.exports