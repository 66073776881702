/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {cascadeDeleteApplication} from '@/graphql/cascade/application';
import {cascadeDeleteInvoice} from '@/graphql/cascade/invoice';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Hotel may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Students and Chaperones associated with this Hotel will be modified.<br/>
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Hotel was successfully deleted.' }
}

export async function cascadeDeleteHotel(hotelId, swalCallback) {
    try {
        /** Update StudentEvent records associated to this Hotel **/
        const studentEvents = await listStudentEventsByHotel(hotelId, swalCallback)
        await batchProcessWithRetry(studentEvents, async (studentEvent) => {
            await API.graphql(graphqlOperation(updateStudentEventMutation, { input: { id: studentEvent.id, hotelId: null }}));
        }, { title: 'Updating Student Events', callback: swalCallback })

        /** Update Chaperone records associated to this Hotel **/
        const chaperones = await listChaperonesByHotel(hotelId, swalCallback)
        await batchProcessWithRetry(chaperones, async (chaperone) => {
            await API.graphql(graphqlOperation(updateChaperoneMutation, { input: { id: chaperone.id, hotelId: null }}));
        }, { title: 'Updating Chaperones', callback: swalCallback })


        /** Delete Hotel **/
        await deleteHotel(hotelId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteHotel(hotelId, swalCallback) {
    const title = 'Deleting Hotel'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteHotelMutation, { input: { id: hotelId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}


async function listChaperonesByHotel(hotelId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { hotelId: hotelId, limit: 250, nextToken: nextToken }
            const response = await API.graphql(graphqlOperation(listChaperonesByHotelQuery, input));
            items.push(...response.data.listChaperonesByHotel.items)
            nextToken = response.data.listChaperonesByHotel.nextToken;
            swalCallback(new SwalData('Loading Chaperones', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

async function listStudentEventsByHotel(hotelId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { hotelId: hotelId, limit: 250, nextToken: nextToken }
            const response = await API.graphql(graphqlOperation(listStudentEventsByHotelQuery, input));
            items.push(...response.data.listStudentEventsByHotel.items)
            nextToken = response.data.listStudentEventsByHotel.nextToken;
            swalCallback(new SwalData('Loading Student Events', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const deleteHotelMutation = /* GraphQL */ `
    mutation DeleteHotel(
        $input: DeleteHotelInput!
        $condition: ModelHotelConditionInput
    ) {
        deleteHotel(input: $input, condition: $condition) {
            id
        }
    }
`;

const listChaperonesByHotelQuery = /* GraphQL */ `
    query ListChaperonesByHotel(
        $hotelId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelChaperoneFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listChaperonesByHotel(
            hotelId: $hotelId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;
const listStudentEventsByHotelQuery = /* GraphQL */ `
    query ListStudentEventsByHotel(
        $hotelId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentEventFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudentEventsByHotel(
            hotelId: $hotelId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;
const updateChaperoneMutation = /* GraphQL */ `
    mutation UpdateChaperone(
        $input: UpdateChaperoneInput!
        $condition: ModelChaperoneConditionInput
    ) {
        updateChaperone(input: $input, condition: $condition) {
            id
        }
    }
`;
const updateStudentEventMutation = /* GraphQL */ `
    mutation UpdateStudentEvent(
        $input: UpdateStudentEventInput!
        $condition: ModelStudentEventConditionInput
    ) {
        updateStudentEvent(input: $input, condition: $condition) {
            id
        }
    }
`;


